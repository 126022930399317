import { FilterFormFieldsEnum } from "@src/pages/ab-test-explore/enum";
import { Checkbox, Form, Select, Tooltip } from "antd";
import { FormInstance } from "antd/lib/form/Form";
import { InputSkeletonLayout } from "../../layout/input-skeleton-layout";
import { useLoadAbExploration } from "@src/hooks/ab-testing/use-ab-exploration-get-result";
import { SupportedModelAlias } from "@src/constant/ab-testing/ad-testing-exploration.enum";
import React from "react";

export const AbaIdFilter: React.FC<{ form: FormInstance }> = ({ form }) => {
  const productCodeWatch = Form.useWatch(FilterFormFieldsEnum.PRODUCT_CODE, form);
  const isReady = productCodeWatch;
  const { result, isLoading } = useLoadAbExploration({
    productCode: productCodeWatch,
    modelAlias: SupportedModelAlias.TICKET_INFO,
    query: {
      dimensions: ["aba_id", "ticket_name", "exp_id"],
      limit: 10000,
      order: [["aba_id", "desc"]],
    },
    options: {
      // TODO: get form value instead
      cohort_date: "first_signal_date",
    },
    isSkip: !isReady,
  });
  React.useEffect(() => {
    form.setFieldValue(FilterFormFieldsEnum.IS_ABA_LOADING, isLoading);
  }, [form, isLoading]);

  const options = isLoading
    ? []
    : result?.map((item: any) => {
        return {
          label: (
            <Tooltip
              mouseLeaveDelay={0}
              title={
                <div className="flex flex-col">
                  <span> Aba ID: {item["aba_id"]}</span>
                  <span
                    style={{
                      wordBreak: "break-all",
                    }}
                  >
                    Experiment: {item["ticket_name"] ?? item["exp_id"]}
                  </span>
                </div>
              }
            >
              <div className="flex ">
                <span>{item["aba_id"]}</span> <span className="px-1">-</span>
                <span className="truncate max-w-[140px]">{item["ticket_name"] ?? item["exp_id"]}</span>
              </div>
            </Tooltip>
          ),
          value: item["aba_id"],
          origin: item,
        };
      });
  return (
    <InputSkeletonLayout width={200} isLoading={isLoading} label="Aba Id" required={true}>
      <Form.Item label="Aba Id" name={FilterFormFieldsEnum.ABA_ID} required>
        <Select
          disabled={!productCodeWatch}
          allowClear
          placeholder="Select Aba Id"
          options={options}
          showSearch
          filterOption={(input, option) => {
            const { aba_id, exp_id, ticket_name } = option?.origin as any;
            if (ticket_name) {
              return (
                ticket_name.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                aba_id.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
              );
            }
            return (
              exp_id.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
              aba_id.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
            );
          }}
          onChange={() => {
            form.setFieldsValue({ [FilterFormFieldsEnum.IS_ABA_ID_CHANGED]: true });
          }}
          style={{
            width: 200,
          }}
        />
      </Form.Item>
      <Form.Item name={FilterFormFieldsEnum.IS_ABA_ID_CHANGED} valuePropName="checked" hidden>
        <Checkbox />
      </Form.Item>
      <Form.Item name={FilterFormFieldsEnum.IS_ABA_LOADING} valuePropName="checked" hidden>
        <Checkbox />
      </Form.Item>
    </InputSkeletonLayout>
  );
};
