import React from "react";
import { Empty, Form, Input, Select } from "antd";
import { FormInstance } from "antd/lib/form/Form";
import { FilterFormFieldsEnum } from "@src/pages/ab-test-explore/enum";
import { useList } from "@refinedev/core";
import { EnumRole } from "@src/types/role";
import { IProduct } from "@src/types/user";
import AmanotesIcon from "@src/assets/images/amanotes_icon.png";
import { useMe } from "@src/hooks/use-me";

export const ProductFilter: React.FC<{ form: FormInstance<any> }> = ({ form }) => {
  const { roles, isSuperAdmin } = useMe();

  const productCodesFiltered = roles.map((r: any) => r.productCode);
  const { data, isLoading } = useList<IProduct>({
    resource: "products",
    dataProviderName: "nestjsx",
    filters: !isSuperAdmin
      ? [
          {
            field: "code",
            operator: "in",
            value: productCodesFiltered,
          },
        ]
      : [],
    queryOptions: {
      enabled: productCodesFiltered.length > 0,
    },
    pagination: {
      pageSize: 100,
    },
  });
  const products = (data?.data || []).map((item) => {
    return {
      ...item,
      productCode: item.code,
      icon: item.icon || AmanotesIcon,
    };
  });
  const renderLabelProduct = (product: any) => {
    return (
      <div className="flex items-center">
        <img src={product.icon} className="w-4 h-4 rounded-[2px] mr-2" />
        {product.name} - {product.productCode}
      </div>
    );
  };

  const options = React.useMemo(() => {
    return products.map((product) => {
      const role = roles.find((r: { productCode: string }) => r.productCode === product.code);
      const disabled = !isSuperAdmin && role.role === EnumRole.ProductViewer;
      return {
        label: renderLabelProduct(product),
        value: product.code,
        title: product.name,
        disabled,
      };
    });
  }, [products]);

  return (
    <div>
      <Form.Item label="Product" name={FilterFormFieldsEnum.PRODUCT_CODE} required className="pb-2">
        <Select
          options={options}
          loading={isLoading}
          placeholder="Select product"
          showSearch
          filterOption={(input, option) => {
            const { title, value } = option as any;
            if (!value || !title) return false;
            return (
              title.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
              value.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
            );
          }}
          allowClear
          style={{ width: 300 }}
          onChange={() => {
            form.setFieldsValue({ [FilterFormFieldsEnum.ABA_ID]: [] });
          }}
          notFoundContent={
            <Empty className="text-[12px]" description="You are not in product! Please contact your admin" />
          }
        />
      </Form.Item>
    </div>
  );
};
